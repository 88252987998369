<template>
  <div class="mortgage--listing" v-if="listing">
    <div class="mortgage--listing-image m-auto">
      <img :src="listing.primary_photo" />
    </div>
    <div class="py-3 px-4 m-auto">
      <div class="mortgage--listing-price mb-2">
        {{ listing.view_to_offer ? 'View To Offer' : listing.sell_price }}
      </div>
      <div class="mortgage--listing-title">{{ listing.listing_title }}</div>
      <div class="mortgage--listing-type">Property Type: {{ listing.property_type_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['listing'],
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.mortgage--listing {
  width: 100%;
  display: flex;
  border-radius: 16px;
  box-shadow: $box-shadow-hover;

  .mortgage--listing-image {
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
    }
  }

  .mortgage--listing-price {
    font-size: $font-xl;
  }

  .mortgage--listing-title {
    font-size: $font-md;
    color: #666666;
  }

  .mortgage--listing-type {
    font-size: $font-sm;
  }
}
</style>
